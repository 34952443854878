import Logo from '~/core/ui/Logo';

function AuthPageShell({ children }: React.PropsWithChildren) {
  return (
    <div
      className={
        'flex h-screen flex-col items-center justify-center space-y-4' +
        ' md:space-y-8 lg:bg-gray-50 lg:dark:bg-background' +
        ' animate-in fade-in slide-in-from-top-8 duration-1000'
      }
    >
      <Logo className='h-20'/>

      <div
        className={`flex w-full max-w-sm flex-col items-center space-y-4 rounded-xl border-transparent bg-white px-2 py-1 dark:bg-transparent md:w-8/12 md:border md:px-8 md:py-6 lg:w-5/12 lg:px-6 lg:shadow-xl dark:lg:border-dark-800 lg:dark:bg-background dark:lg:shadow-[0_0_1200px_0] lg:dark:shadow-primary/30 xl:w-4/12 2xl:w-3/12`}
      >
        {children}
      </div>
    </div>
  );
}

export default AuthPageShell;
